<template>
  <section>
    <div class="py-10 bg-gray-50 radius-for-skewed">
      <div class="container mx-auto px-4">
        <div class="mb-8 md:mb-16 max-w-xl mx-auto text-center">
          <form class="flex justify-center mb-2">
            <input v-if="applications && applications.length > 5" v-model="searchTerm"
              class="w-2/3 p-4 text-xs font-heading bg-white focus:border-gray-500 focus:outline-none rounded-l"
              placeholder="Meine Anwendungen filtern...">
            <button v-if="applications && applications.length > 5" class="pr-4 rounded-r-lg bg-white text-sky-600">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
              <button v-if="connectionType == 'remoteapp'"
              class="ml-4 w-full py-2 bg-sky-600 hover:bg-sky-700 rounded text-sm font-bold text-gray-50 text-center" @click="openPluginUrl()">⬇️ RemoteApp
              Plugin herunterladen ⬇️</button>
          </form>
          <div class="mb-6">
            <label class="mr-2">
              <input type="radio" name="connectionType" value="remoteapp" v-model="connectionType">
              <span class="ml-1">RemoteApp (für Windows empfohlen)</span>
            </label>
            <label class="mr-2">
              <input type="radio" name="connectionType" value="webbrowser" v-model="connectionType">
              <span class="ml-1">Webbrowser</span>
            </label>
            <label v-if="false">
              <input type="radio" name="connectionType" value="rdpfile" v-model="connectionType">
              <span class="ml-1">RDP Datei</span>
            </label>
          </div>
        </div>
        <p v-if="showApplicationIsLoadingButton" class="mb-4 w-full py-2 bg-sky-600 rounded text-sm font-bold text-gray-50 text-center">Die Anwendung wird geladen</p>

          <div class="flex flex-wrap justify-center" >
            <div class="flex-none w-64 h-64" v-for="application in filteredApplications" v-bind:key="application" @click="createApplicationContainer(application._id)">
            <div class="relative mx-auto h-32 w-32 rounded-lg">
              <img class="relative h-full w-full rounded-lg object-cover" v-if="application.icon"
                :src="require(`../assets/images/${application.icon}`)">
              <img v-else class="relative h-full w-full rounded-lg object-cover"
                src="../assets/images/cio.png"
                alt="">
            </div>
            <div class="p-6">
              <h3 class="mb-4 font-bold font-heading text-center">{{ application.name }}</h3>
            </div>
          </div>

          </div>
          <div v-if="lastLaunchedApplication && lastLaunchedApplication.serverId" class="text-center text-sm">
            <a>Server-ID: {{ lastLaunchedApplication.serverId }}</a>
          </div>
      </div>
    </div>
  </section>
  <div>
  </div>
  <vue-final-modal v-model="showPluginNotFoundModal" classes="modal-container" content-class="modal-content" :esc-to-close="true" :lock-scroll="false">
  <div class="py-5 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto px-4">
      <div class="mx-auto max-w-md text-center">
        <h2 class="text-4xl lg:text-5xl font-bold font-heading">RemoteApp Plugin nicht gefunden</h2>
        <p class="mt-10">Es sieht so aus, als ob unser RemoteApp Plugin noch nicht installiert ist. Bitte klicke auf den nachfolgenden Button, um es herunterzuladen:</p>
        <br>
                    <button v-if="connectionType == 'remoteapp' || connectionType == 'rdpfile'"
              class="mb-3 w-full py-2 bg-sky-600 hover:bg-sky-700 rounded text-sm font-bold text-gray-50" @click="openPluginUrl()">⬇️ RemoteApp
              Plugin herunterladen ⬇️</button>
              <button class="text-xs text-sky-600 hover:underline" @click="showPluginNotFoundModal = false">Schließen</button>
      </div>
    </div>
  </div>
</vue-final-modal>
<vue-final-modal v-model="showOpenGuacamoleUrlModal" classes="modal-container" content-class="modal-content" :esc-to-close="true" :lock-scroll="false">
  <div class="py-5 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto px-4">
      <div class="mx-auto max-w-md text-center">
              <button v-if="lastLaunchedApplication"
              class="w-full py-2 px-2 bg-sky-600 hover:bg-sky-700 rounded text-sm font-bold text-gray-50" @click="openGuacamoleUrl()">Klicken Sie hier um die Anwendung zu starten</button>
      </div>
    </div>
  </div>
</vue-final-modal>
</template>

<script>
import axios from 'axios';
import downloadjs from 'downloadjs';
import copy from 'copy-to-clipboard';
import Fuse from 'fuse.js'
import customProtocolCheck from "custom-protocol-check";
import platform from 'platform';

export default {
  name: 'Applications',
  data() {
    return {
      showPluginNotFoundModal: false,
      showOpenGuacamoleUrlModal: false,
      applications: null,
      filteredApplications: this.applications,
      showModal: false,
      lastLaunchedApplication: null,
      searchTerm: null,
      connectionType: 'remoteapp',
      showRemoteAppSuccessModal: true,
      showApplicationIsLoadingButton: false,
      inProgress: false,
    }
  },
  mounted() {
    // 60e83c60f24b4f8c861570be
    if(!platform.os.family.toLowerCase().includes('windows')) {
      this.connectionType = 'webbrowser'
    }
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/users/${this.$store.getters.user['userId']}/applications`)
      .then(response => {
        this.applications = response.data
        this.filteredApplications = response.data
      })

  },
  methods: {
    openPluginUrl() {
      console.log(process.env.BASE_URL)
      window.open(`${process.env.BASE_URL}SetupWindowsPlugin.msi`)
      //window.open('https://win-api-files.fra1.digitaloceanspaces.com/WindowsPlugin.msi')
    },
    openGuacamoleUrl() {
      window.open(this.lastLaunchedApplication.guacamoleUrl, '_blank').focus()
      this.showOpenGuacamoleUrlModal = false

    },
    async createApplicationContainer(applicationId) {
      if (!this.inProgress) {
        this.inProgress = true
        // launchButtonAnimated
        let application = this.filteredApplications.find(element => element._id == applicationId);
        application.launchButtonAnimated = 'animate-spin'
        application.buttonDisabled = true
        this.showApplicationIsLoadingButton = true
        document.body.style.cursor = "wait"

        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/applications/${applicationId}`)

        this.lastLaunchedApplication = response.data

        this.openApplication()

        //copy(response.data.rdpPassword)
        //downloadjs(atob(response.data.rdpFileBase64), 'test.rdp')

        //this.showModal = true

        application.launchButtonAnimated = undefined
        application.buttonDisabled = false
        this.showApplicationIsLoadingButton = false
        document.body.style.cursor = "auto"
        this.inProgress = false
      }
    },
    async copyLastPasswordToClipboard() {
      copy(this.lastLaunchedApplication.rdpPassword)
    },
    async downloadLastRdpFile() {
      downloadjs(atob(this.lastLaunchedApplication.rdpFileBase64), `${this.lastLaunchedApplication.applicationName}.rdp`)
    },
    async openApplication() {
        if (this.connectionType === 'remoteapp') {
        //window.open(`openrdp://${response.data.rdpFileBase64}/${response.data.rdpPassword}`, "_self")

        if (platform.name === "Opera") {
          window.open(`openrdp://${this.lastLaunchedApplication.rdpFileBase64}/${this.lastLaunchedApplication.rdpPassword}`, "_self")
        } else {
          customProtocolCheck(
          `openrdp://${this.lastLaunchedApplication.rdpFileBase64}/${this.lastLaunchedApplication.rdpPassword}`,
          () => {
            this.showPluginNotFoundModal = true
            console.log("Custom protocol not found.");
          },
          () => {
            this.showPluginNotFoundModal = false
            console.log("Custom protocol found and opened the file successfully.");
          }, 5000
        );
        }

      } else if (this.connectionType === 'rdpfile') {
        copy(this.lastLaunchedApplication.rdpPassword)
        downloadjs(atob(this.lastLaunchedApplication.rdpFileBase64), `${this.lastLaunchedApplication.applicationName}.rdp`)
      } else {
        this.showOpenGuacamoleUrlModal = true
      }
    }
  },
  watch: {
    searchTerm: async function (val) {
      if (val === '') {
        return this.filteredApplications = this.applications
      }

      const options = {
        includeScore: false,
        findAllMatches: true,
        // Search in `author` and in `tags` array
        keys: ['name']
      }

      const fuse = new Fuse(this.applications, options)

      const result = fuse.search(val)

      let cleanedResult = []

      result.forEach(i => {
        cleanedResult.push(i.item)
      })

      return this.filteredApplications = cleanedResult
    }
  }
}
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>